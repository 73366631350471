import React, { FC, useState, useEffect, useMemo } from 'react';
import { UserIcon, ScoreIcon, ResultsText, Button, LogoIcon } from '../../components';
import { ACCENTS_IDS, FeedbackType } from '../../types';
import { Loading } from '../Loading';
import {
  Container,
  Header,
  Vector,
  MainWrapper,
  UserBlock,
  AvatarImg,
  MainBlock,
  ButtonWrapper,
  Text,
  Accent,
} from './styles';

import lessonPath from '../../assets/lessons_congratulation.svg';
import applePath from '../../assets/apple.svg';
import hindiPath from '../../assets/accent_hindi.svg';
import americanPath from '../../assets/accent_USA.svg';
import chinaPath from '../../assets/accent_china.svg';
import britishPath from '../../assets/accent_british.svg';

const API = 'https://dev.soundsofspeech.io/feedbacks';

export const Results: FC = () => {
  const [feedback, setFeedback] = useState<FeedbackType | null>(null);
  const [avatar, setAvatar] = useState<string>('');

  const formattedScores = useMemo(() => {
    if (feedback) {
      return feedback.score < 10 ? `0${feedback.score}` : `${feedback.score}`;
    }

    return '';
  }, [feedback]);

  const onButtonClick = () => {
    window.location.assign('/');
  };

  const isValidURL = (str: string) => {
    try {
      new URL(str);
      return true;
    } catch {
      return false;
    }
  };

  const getFeedback = async (): Promise<void> => {
    const pathnames = window.location.pathname.split('/');
    const id = pathnames[pathnames.length - 1];

    try {
      const result = await fetch(`${API}/${id}`);
      if (result.status === 200) {
        const data: FeedbackType = await result.json();
        setFeedback(data);
        if (data.user_avatar) {
          isValidURL(data.user_avatar) ? setAvatar(data.user_avatar) : getAvatar(data.user_avatar);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getAvatar = async (filename: string): Promise<void> => {
    try {
      const result = await fetch(`${API}/avatar?filename=${filename}`);
      if (result.status === 200) {
        const buffer = await result.arrayBuffer();

        const blob = new Blob([buffer], { type: 'image/*' });
        const url = window.URL.createObjectURL(blob);
        setAvatar(url);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const accent = useMemo(() => {
    switch (feedback?.accent_id) {
      case ACCENTS_IDS.HINDI:
        return hindiPath;
      case ACCENTS_IDS.AMERICANS:
        return americanPath;
      case ACCENTS_IDS.MANDARIN:
        return chinaPath;

      default:
        return britishPath;
    }
  }, [feedback]);

  useEffect(() => {
    getFeedback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!feedback) {
    return <Loading />;
  }

  return (
    <Container>
      <Vector />
      <Accent accent={accent} />

      <MainWrapper>
        <Header>
          <LogoIcon />
        </Header>

        <MainBlock>
          <UserBlock>
            {avatar ? <AvatarImg src={avatar} alt="avatar" /> : <UserIcon />}
            <ResultsText size="large" bold>
              {feedback?.user_name ? `${feedback?.user_name}` : 'User Name'}
            </ResultsText>
          </UserBlock>

          <ResultsText>
            I’ve just finished <ResultsText bold>{feedback.exercise_name}</ResultsText> exercise
            from the courses of the <ResultsText bold>{feedback.accent_name}</ResultsText>.
          </ResultsText>

          <img src={lessonPath} alt="congratulation" />

          <UserBlock>
            <ResultsText size="large" bold color="grey">
              My reward:
            </ResultsText>
            <ScoreIcon />
            <ResultsText size="large" bold color="grey">
              +{formattedScores}
            </ResultsText>
          </UserBlock>
        </MainBlock>

        <ButtonWrapper>
          <Button onClick={onButtonClick}>
            <img src={applePath} alt="apple icon" />
            Try your hand
          </Button>
          <Text color="grey">
            The application will help you improve your understanding and perception of English and
            other.
          </Text>
        </ButtonWrapper>
      </MainWrapper>
    </Container>
  );
};
