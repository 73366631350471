import React, { FC } from 'react';

export const UserIcon: FC = () => (
  <svg width="36" height="36" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40" cy="40" r="40" fill="#F5F5F5" />
    <path
      d="M39.3301 43.1055C41.5628 43.1055 43.8021 42.9799 46.0241 43.1309C51.4007 43.4957 55.9569 48.1588 56.3043 53.5247C56.3457 54.1674 56.3765 54.8368 56.2482 55.4607C55.989 56.7167 55.2434 57.6587 54.073 58.2252C52.1009 59.1805 49.9844 59.6214 47.8359 59.9167C42.9938 60.5848 38.1329 60.5941 33.2721 60.204C30.4956 59.9809 27.7472 59.5667 25.1404 58.5098C23.0801 57.6747 22.1955 56.4628 22.2223 54.5027C22.241 53.1425 22.4788 51.8211 22.9705 50.5478C24.7275 46.0063 29.0152 43.0454 33.88 43.0267C35.6972 43.02 37.513 43.0253 39.3301 43.0253C39.3301 43.0521 39.3301 43.0788 39.3301 43.1055Z"
      fill="#899598"
    />
    <path
      d="M39.3009 38.6803C33.5222 38.6829 28.8029 33.9985 28.7949 28.2491C28.7869 22.4556 33.4741 17.7791 39.2943 17.7778C45.1131 17.7751 49.8096 22.4529 49.8003 28.2437C49.7922 33.9985 45.0851 38.6776 39.3009 38.6803Z"
      fill="#899598"
    />
  </svg>
);
