import { styled } from 'styled-components';

export const LogoContainer = styled.div`
  position: absolute;
  top: 31px;
  left: 110px;

  @media screen and (max-width: 430px) {
    top: 27px;
    left: 29px;
  }
`;

export const HeaderBlock = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 430px) {
    padding-top: 54px;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const HeaderLeft = styled.div`
  margin-left: 110px;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: center;

  @media screen and (max-width: 430px) {
    margin-left: 0;
  }
`;

export const HeaderRight = styled.div`
  flex-basis: 50%;
  margin-top: 41px;
  object-fit: contain;

  & svg {
    max-width: 472px;
    max-height: 517px;

    @media screen and (max-width: 430px) {
      max-width: 289px;
      max-height: 317px;
    }
  }
`;

export const ProblemBlock = styled.div`
  width: 100%;
  margin-top: 70px;
  display: flex;

  @media screen and (max-width: 430px) {
    margin-top: 41px;
    flex-direction: column;
    align-items: center;
  }
`;

export const ProblemLeft = styled.div`
  flex-basis: 60%;
  margin-left: 52px;
  margin-right: 41px;
  object-fit: contain;

  & img {
    max-width: 628px;
    max-height: 386px;
  }
`;

export const ProblemRight = styled.div`
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
`;

export const PlayBlock = styled.div`
  display: flex;

  & span {
    margin-left: 18px;
  }
`;

export const UndestandingBlock = styled.div`
  width: 100%;
  margin-top: 183px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 430px) {
    margin-top: 110px;
  }
`;

export const UndestandingIcons = styled.div`
  width: 70%;
  margin: 72px 0 45px;
  padding: 0 208px;
  display: flex;
  justify-content: space-between;

  & div {
    display: flex;
    flex-direction: column;
    align-items: center;

    & img {
      width: 84px;
      height: 84px;
    }
    @media screen and (max-width: 430px) {
      margin-top: 24px;
    }
  }

  @media screen and (max-width: 430px) {
    margin-top: 0;
    padding: 0;
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterBlock = styled.div`
  width: 100%;
  margin-top: 66px;
  padding-bottom: 50px;
  display: flex;

  @media screen and (max-width: 430px) {
    margin-top: 24px;
    justify-content: center;
  }
`;

export const FooterLeft = styled.div`
  flex-basis: 50%;
  margin-left: 120px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 430px) {
    margin-left: 0px;
    align-items: center;
    justify-content: center;
  }
`;

export const FooterRight = styled.div`
  margin-right: 120px;
  flex-basis: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
