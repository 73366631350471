import React, { FC } from 'react';
import { AppButton, Text } from '../../components';
import { UndestandingBlock, UndestandingIcons } from './styled';

import travelImgPath from '../../assets/travel.png';
import callImgPath from '../../assets/video-call.png';
import cinemaImgPath from '../../assets/cinema.png';
import supportImgPath from '../../assets/support.png';

export const Undestanding: FC = () => {
  return (
    <UndestandingBlock>
      <Text size="large" bold align="center">
        Understanding the accent
        <br />
        will help in
      </Text>
      <UndestandingIcons>
        <div>
          <img src={travelImgPath} alt="loading..." />
          <Text mt={18} align="center">
            Travel and
            <br />
            communication
            <br />
            with people
          </Text>
        </div>

        <div>
          <img src={callImgPath} alt="loading..." />
          <Text mt={18} align="center">
            Watching tutorial
            <br />
            videos
          </Text>
        </div>

        <div>
          <img src={cinemaImgPath} alt="loading..." />
          <Text mt={18} align="center">
            Watching Indian
            <br />
            Movies
          </Text>
        </div>

        <div>
          <img src={supportImgPath} alt="loading..." />
          <Text mt={18} align="center">
            Calls to technical
            <br />
            support
          </Text>
        </div>
      </UndestandingIcons>
      <AppButton />
    </UndestandingBlock>
  );
};
