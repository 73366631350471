import React, { FC, useState, useEffect } from 'react';
import { Landing, Loading, Privacy, Results, TOS } from './pages';
import { PAGES } from './types';

const App: FC = () => {
  const [currentPage, setCurrentPage] = useState(PAGES.LOADING);

  useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname.length > 1) {
      if (pathname === '/terms-of-service') {
        setCurrentPage(PAGES.TOS);
      } else if (pathname === '/privacy-policy') {
        setCurrentPage(PAGES.PRIVACY);
      } else {
        setCurrentPage(PAGES.RESULTS);
      }
    } else {
      setCurrentPage(PAGES.LANDING);
    }
  }, []);

  if (currentPage === PAGES.RESULTS) {
    return <Results />;
  }

  if (currentPage === PAGES.TOS) {
    return <TOS />;
  }

  if (currentPage === PAGES.PRIVACY) {
    return <Privacy />;
  }

  if (currentPage === PAGES.LANDING) {
    return <Landing />;
  }

  return <Loading />;
};

export default App;
