import React, { FC, PropsWithChildren } from 'react';
import { Container, Vector1, Vector2, Content, Vector3 } from './styled';

export const Layout: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Container>
      <Vector1 />
      <Vector2 />
      <Vector3 />
      <Content>{children}</Content>
    </Container>
  );
};
