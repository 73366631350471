import React, { FC } from 'react';

export const PlayIcon: FC = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="64" height="64" rx="12" fill="#D9EB5E" />
    <path
      d="M39.5 30.634C40.1667 31.0189 40.1667 31.9811 39.5 32.366L28.25 38.8612C27.5833 39.2461 26.75 38.765 26.75 37.9952L26.75 25.0048C26.75 24.235 27.5833 23.7539 28.25 24.1388L39.5 30.634Z"
      fill="#0A0A0A"
    />
  </svg>
);
