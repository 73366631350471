import React, { FC, PropsWithChildren } from 'react';
import { styled } from 'styled-components';

type TextProps = {
  mt?: number;
  size?: 'small' | 'large';
  bold?: boolean;
  color?: 'grey';
};

const StyledText = styled.span.withConfig({
  shouldForwardProp: (prop) => !'size'.includes(prop),
})<{
  mt?: number;
  size?: 'small' | 'large';
  bold?: boolean;
  color?: 'grey';
}>`
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : '0px')};
  text-align: center;
  font-weight: ${(props) => (props.bold ? 500 : 400)};
  font-size: ${(props) => (props.size === 'large' ? `18px` : '16px')};
  line-height: ${(props) => (props.size === 'large' ? `29px` : '24px')};
  color: ${(props) => (props.color === 'grey' ? '#899598' : '#000')};
`;

export const ResultsText: FC<PropsWithChildren<TextProps>> = ({
  children,
  mt,
  size,
  bold,
  color,
}) => {
  return (
    <StyledText mt={mt} size={size} bold={bold} color={color}>
      {children}
    </StyledText>
  );
};
