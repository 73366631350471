import React, { FC } from 'react';
import { useLottie } from 'lottie-react';
import { AppButton, LogoIcon, Text } from '../../components';
import { LogoContainer, HeaderBlock, HeaderLeft, HeaderRight } from './styled';

import lottie from '../../lottie/cMHARNslJq.json';

export const Header: FC = () => {
  const isMobile = window.screen.availWidth < 430;
  const options = {
    animationData: lottie,
    loop: true,
    style: { transform: 'rotateY(180deg)' },
  };

  const { View } = useLottie(options);

  return (
    <HeaderBlock>
      <LogoContainer>
        <LogoIcon />
      </LogoContainer>

      <HeaderLeft>
        <Text mt={isMobile ? 0 : 76} size="large" bold>
          Learn to really listen
        </Text>
        <Text mt={12} size="small">
          Explore how sounds change in different
          <br />
          accents, listen to diverse dialogues, and
          <br />
          improve your understanding with us.
          <br />
          <br />
        </Text>
        <AppButton />
      </HeaderLeft>

      <HeaderRight>{View}</HeaderRight>
    </HeaderBlock>
  );
};
