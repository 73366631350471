import React, { FC, PropsWithChildren } from 'react';
import { styled } from 'styled-components';

type TextProps = {
  mt?: number;
  size?: 'xs' | 'small' | 'large';
  bold?: boolean;
  color?: 'grey' | 'white';
  align?: 'center' | 'left' | 'right';
};

const StyledText = styled.span.withConfig({
  shouldForwardProp: (prop) => !'size'.includes(prop),
})<{
  mt?: number;
  size?: 'xs' | 'small' | 'large';
  bold?: boolean;
  color?: 'grey' | 'white';
  align?: 'center' | 'left' | 'right';
}>`
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : '0px')};
  text-align: ${(props) => (props.align ? `${props.align}` : 'left')};
  font-weight: ${(props) => (props.bold ? 500 : 300)};
  font-size: ${(props) =>
    props.size === 'large' ? `3rem` : props.size === 'xs' ? '0.875rem' : '1.25rem'};
  line-height: ${(props) => props.size === 'large' && `120%`};
  color: ${(props) =>
    props.color === 'grey' ? '#899598' : props.color === 'white' ? '#FFF' : '#000'};

  @media screen and (max-width: 430px) {
    font-size: ${(props) =>
      props.size === 'large' ? `1.625rem` : props.size === 'xs' ? '0.875rem' : '1rem'};
    line-height: ${(props) => props.size === 'large' && `120%`};
  }
`;

export const Text: FC<PropsWithChildren<TextProps>> = ({
  children,
  mt,
  size,
  bold,
  color,
  align,
}) => {
  return (
    <StyledText mt={mt} size={size} bold={bold} color={color} align={align}>
      {children}
    </StyledText>
  );
};
