import React, { FC, useState, useEffect } from 'react';
import { useLottie } from 'lottie-react';
import { Text, PlayIcon, PauseIcon } from '../../components';

import { ProblemBlock, ProblemLeft, ProblemRight, PlayBlock } from './styled';

import lottie from '../../lottie/3O0DvO92IX.json';
import audioPath from '../../assets/audio/audio.mp3';

export const Problem: FC = () => {
  const [audio] = useState(new Audio(audioPath));
  const [playing, setPlaying] = useState(false);

  const options = {
    animationData: lottie,
    loop: true,
  };

  const { View } = useLottie(options);

  const onTogglePlay = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    playing ? audio.play() : audio.pause();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', onTogglePlay);
    return () => {
      audio.removeEventListener('ended', onTogglePlay);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  return (
    <ProblemBlock>
      <ProblemLeft>{View}</ProblemLeft>

      <ProblemRight>
        <Text size="large" bold>
          Problem
        </Text>
        <Text mt={16} size="small">
          Sometimes understanding spoken
          <br />
          language is not always easy. In addition to
          <br />
          grammar, it is very important to understand
          <br />
          speech, because it is much easier to
          <br />
          communicate with people if you
          <br />
          understand them.
          <br />
          <br />
        </Text>

        <PlayBlock>
          <button onClick={onTogglePlay}>{playing ? <PauseIcon /> : <PlayIcon />}</button>
          <Text size="small" color="grey">
            Press play and try to understand
            <br />
            spoken language
          </Text>
        </PlayBlock>
      </ProblemRight>
    </ProblemBlock>
  );
};
