import React, { FC } from 'react';
import { LogoIcon } from '../../components';
import { FooterBlock, FooterLeft } from './styled';

export const Footer: FC = () => {
  return (
    <FooterBlock>
      <FooterLeft>
        <LogoIcon />
        {/* <Text mt={6} size="xs">
          Oken Technologies Inc. Suite C16,
          <br />
          244 5th Avenue, New York,
          <br />
          NY 10001 United States of America
        </Text> */}
      </FooterLeft>

      {/* <FooterRight>
        <Text size="xs" align="right">
          © 2022 Oken Technologies Inc. All
          <br />
          Rights Reserved.
        </Text>
      </FooterRight> */}
    </FooterBlock>
  );
};
