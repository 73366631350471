import React, { FC } from 'react';

export const AppStoreIcon: FC = () => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.2063 31.275C20.1355 31.275 14.0648 31.275 7.99396 31.275C7.95919 31.268 7.92442 31.2472 7.88965 31.2472C4.96203 30.8299 2.84108 28.257 2.85499 25.385C2.8828 19.8496 2.86194 14.3212 2.86194 8.78591C2.86194 8.47993 2.8689 8.18091 2.91062 7.87494C3.30004 5.03078 5.75478 2.84028 8.61981 2.84028C14.2803 2.83333 19.9478 2.82637 25.6083 2.84724C27.4511 2.85419 28.9392 3.66085 30.0936 5.08641C30.7751 5.92783 31.1436 6.90834 31.3105 7.96534C31.3105 14.0361 31.3105 20.1069 31.3105 26.1777C31.2966 26.2612 31.2758 26.3377 31.2619 26.4211C30.9768 27.9718 30.2118 29.2236 28.9462 30.1693C28.1326 30.7743 27.2077 31.1081 26.2063 31.275ZM17.1036 29.5852C19.8087 29.5852 22.5068 29.5852 25.2119 29.5852C25.5457 29.5852 25.8795 29.5643 26.2063 29.5017C28.2856 29.1053 29.6277 27.4364 29.6346 25.2389C29.6346 19.7871 29.6346 14.3421 29.6346 8.89022C29.6346 8.57729 29.6138 8.25741 29.5512 7.95143C29.1548 5.8722 27.4859 4.53009 25.2815 4.53009C19.8296 4.53009 14.3846 4.53009 8.93274 4.53009C8.62676 4.53009 8.32079 4.55095 8.02177 4.60658C5.97731 4.9821 4.59347 6.63714 4.58652 8.772C4.57261 14.2865 4.57261 19.801 4.58652 25.3154C4.59347 26.9288 5.28192 28.1874 6.68661 29.0219C7.38201 29.4391 8.1539 29.5782 8.94665 29.5782C11.6656 29.5852 14.3846 29.5852 17.1036 29.5852Z"
      fill="white"
    />
    <path
      d="M14.1065 21.7967C12.4028 21.7967 10.699 21.8037 9.00228 21.7967C8.32775 21.7967 7.77839 21.3308 7.64626 20.6702C7.51414 20.0374 7.84097 19.3907 8.43206 19.1125C8.62677 19.0221 8.83539 18.9804 9.05096 18.9873C9.85762 18.9873 10.6643 18.9804 11.464 18.9943C11.6517 18.9943 11.77 18.9317 11.8673 18.7648C13.0147 16.769 14.1691 14.7733 15.3234 12.7775C15.4277 12.5967 15.4347 12.4437 15.3234 12.2629C15.0244 11.7622 14.7184 11.2615 14.4542 10.7469C13.9257 9.71078 14.6698 8.59119 15.8102 8.66769C16.29 8.6955 16.6447 8.94585 16.902 9.34222C17.1036 9.64819 17.1036 9.64819 17.2983 9.35613C17.6599 8.81372 18.2371 8.55642 18.8282 8.6955C19.4332 8.83458 19.8435 9.29354 19.9339 9.94026C19.9826 10.3088 19.8435 10.6287 19.6696 10.9416C18.6544 12.701 17.6321 14.4673 16.6169 16.2266C16.137 17.0611 15.6572 17.8956 15.1704 18.7231C15.0244 18.9804 15.0244 18.9943 15.3234 18.9943C16.1162 18.9943 16.9159 19.0013 17.7086 18.9943C17.9103 18.9943 18.0285 19.0569 18.1328 19.2377C18.5709 20.0096 19.016 20.7745 19.461 21.5464C19.6001 21.7967 19.5862 21.8176 19.3011 21.8176C17.5626 21.8037 15.838 21.8037 14.1065 21.7967Z"
      fill="white"
    />
    <path
      d="M23.7655 25.4615C23.1814 25.4545 22.7711 25.1694 22.486 24.6757C21.9575 23.7647 21.429 22.8538 20.9074 21.9358C19.8922 20.1834 18.8838 18.4241 17.8686 16.6786C17.7504 16.477 17.7434 16.324 17.8686 16.1223C18.3067 15.3922 18.7239 14.6481 19.1481 13.911C19.3428 13.5702 19.3637 13.5702 19.5584 13.904C20.4624 15.4617 21.3664 17.0263 22.2565 18.591C22.4165 18.8761 22.5903 18.9943 22.9241 18.9874C23.6612 18.9595 24.4053 18.9734 25.1424 18.9804C25.9769 18.9874 26.6166 19.6063 26.6097 20.3921C26.6027 21.1848 25.9699 21.7898 25.1285 21.7968C24.8851 21.7968 24.6487 21.8037 24.4053 21.7968C24.148 21.7828 24.148 21.8732 24.2593 22.068C24.5096 22.4852 24.7599 22.9094 24.9894 23.3405C25.4275 24.168 25.0868 25.0651 24.2314 25.3919C24.0784 25.4476 23.9255 25.4684 23.7655 25.4615Z"
      fill="white"
    />
    <path
      d="M10.6851 25.4615C9.46819 25.4615 8.79365 24.5157 9.10658 23.5909C9.12744 23.5213 9.16917 23.4587 9.19003 23.3892C9.32911 22.9928 9.57945 22.8329 10.0245 22.8538C10.8242 22.8955 11.6309 22.8607 12.4375 22.8677C12.7226 22.8677 12.7435 22.9024 12.6044 23.1458C12.3054 23.6743 11.9994 24.2028 11.6865 24.7313C11.4083 25.1972 10.9981 25.4615 10.6851 25.4615Z"
      fill="white"
    />
  </svg>
);
