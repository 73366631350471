import React, { FC } from 'react';

export const ScoreIcon: FC = () => (
  <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 29.5179C23.0081 29.5179 29.5 23.0261 29.5 15.0179C29.5 7.00981 23.0081 0.517944 15 0.517944C6.99187 0.517944 0.5 7.00981 0.5 15.0179C0.5 23.0261 6.99187 29.5179 15 29.5179Z"
      fill="#D9D9D9"
      stroke="#5E757B"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1758 25.168C20.8781 25.168 25.5008 20.5453 25.5008 14.843C25.5008 9.14062 20.8781 4.51796 15.1758 4.51796C9.47344 4.51796 4.85078 9.14062 4.85078 14.843C4.85078 20.5453 9.47344 25.168 15.1758 25.168ZM15.1758 26.218C21.458 26.218 26.5508 21.1252 26.5508 14.843C26.5508 8.56072 21.458 3.46796 15.1758 3.46796C8.89354 3.46796 3.80078 8.56072 3.80078 14.843C3.80078 21.1252 8.89354 26.218 15.1758 26.218Z"
      fill="#5E757B"
    />
    <path
      d="M13.2093 21.3922C12.4061 22.0037 10.6808 21.0887 9.35576 19.3484C8.03071 17.6082 7.60768 15.7016 8.41089 15.0901C9.2141 14.4785 10.9394 15.3935 12.2644 17.1338C13.5895 18.8741 14.0125 20.7806 13.2093 21.3922Z"
      fill="#5E757B"
    />
    <path
      d="M21.7371 21.0257C20.2645 22.1469 16.6746 19.9087 13.7187 16.0265C10.7628 12.1443 9.56038 8.08826 11.0329 6.96707C12.5055 5.84588 16.0954 8.08411 19.0513 11.9663C22.0071 15.8485 23.2096 19.9045 21.7371 21.0257Z"
      fill="#5E757B"
    />
  </svg>
);
