import styled from 'styled-components';
import vectorPath from '../../assets/Vector.svg';

export const Vector = styled.div`
  position: absolute;
  top: calc(50% - 396px);
  left: calc(50% - 446px);
  width: 892px;
  height: 792px;
  background-image: url(${vectorPath});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  z-index: -1;
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`;

export const MainWrapper = styled.div`
  width: 342px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

export const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const UserBlock = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

export const AvatarImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  object-fit: cover;
`;

export const ScoreWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: #899598;
  text-align: center;
`;

export const Accent = styled.div.withConfig({
  shouldForwardProp: (prop) => !'accent'.includes(prop),
})<{
  accent: string;
}>`
  position: absolute;
  bottom: 122px;
  left: calc(50% - 354px);
  width: 708px;
  height: 308px;
  background-image: ${(props) => `url(${props.accent})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  z-index: -1;
`;
