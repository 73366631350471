import React, { FC, useState } from 'react';
import { styled } from 'styled-components';
import { TooltipIcon, CloseIcon } from '../Icon';

const StyledTooltip = styled.div`
  position: absolute;
  top: -40px;
  right: 0;
  width: 240px;
  padding: 32px 28px;
  border: 1px solid #000;
  border-radius: 12px;
  text-align: center;
  color: #899598;
  background-color: #fff;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  left: 260px;
`;

export const Tooltip: FC = () => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const onShow = (): void => {
    setIsHover(true);
  };

  const onClose = (): void => {
    setIsHover(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div onClick={onShow}>{!isHover && <TooltipIcon />}</div>

      {isHover && (
        <StyledTooltip>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
          The application will help you
          <br />
          improve your understanding and
          <br />
          perception of English and other.
        </StyledTooltip>
      )}
    </div>
  );
};
