export enum PAGES {
  LANDING = 'LANDING',
  RESULTS = 'RESULTS',
  LOADING = 'LOADING',
  TOS = 'TOS',
  PRIVACY = 'PRIVACY',
}

export enum ACCENTS_IDS {
  HINDI = '25e5932e-e653-4237-abc8-7085b889bc61',
  AMERICANS = '18d109d6-39d1-4a3f-bfb7-943851132f54',
  MANDARIN = 'e5c17a41-f342-41c9-ac75-1377a57151aa',
}

export type FeedbackType = {
  user_id: string;
  user_avatar: string;
  user_name: string;
  accent_id: ACCENTS_IDS;
  accent_name: string;
  exercise_id: string;
  exercise_name: string;
  score: number;
  created_at: string;
};

export const EXERCISE_ID = '82596c7e-7769-461c-99e1-540c35b57236';
