import React, { FC } from 'react';
import { styled } from 'styled-components';
import { Text } from '../Text';
import { AppStoreIcon } from '../Icon';

const StyledButton = styled.a`
  width: fit-content;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border-radius: 12px;

  & span {
    margin-left: 12px;
  }
`;

export const AppButton: FC = () => {
  return (
    <StyledButton href="https://apps.apple.com/app/id6477531600">
      <AppStoreIcon />
      <Text size="small" bold color="white">
        Download App
      </Text>
    </StyledButton>
  );
};
