import { styled } from 'styled-components';

import vector1Path from '../../assets/vector1.svg';
import vector2Path from '../../assets/vector2.svg';
import vector3Path from '../../assets/vector3.svg';

export const Container = styled.div`
  position: relative;
  width: 100vw;
  min-height: 100vh;
`;

export const Vector1 = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 607px;
  background-image: url(${vector1Path});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  z-index: 1;

  @media screen and (max-width: 430px) {
    height: 317px;
  }
`;

export const Vector2 = styled.div`
  position: absolute;
  bottom: 102px;
  width: 100%;
  height: 822px;
  background-image: url(${vector2Path});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: top;
  z-index: 1;

  @media screen and (max-width: 430px) {
    height: 1050px;
    background-position-x: 65%;
    background-size: cover;
  }
`;

export const Vector3 = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 565px;
  background-image: url(${vector3Path});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: top;
  z-index: 1;

  @media screen and (max-width: 430px) {
    height: 1000px;
    background-position-x: 25%;
    background-size: cover;
  }
`;

export const Content = styled.div`
  position: relative;
  width: 1200px;
  margin: 0 auto;
  z-index: 2;

  @media screen and (max-width: 430px) {
    width: 100%;
  }
`;
