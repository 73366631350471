import React, { FC } from 'react';
import { Layout } from '../../components';
import { Header } from './Header';
import { Problem } from './Problem';
import { Undestanding } from './Undestanding';
import { Footer } from './Footer';

export const Landing: FC = () => {
  return (
    <Layout>
      <Header />
      <Problem />
      <Undestanding />
      <Footer />
    </Layout>
  );
};
