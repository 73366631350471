import React, { FC } from 'react';
import { styled } from 'styled-components';
import { Container, Loader } from '../../components';

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Loading: FC = () => (
  <Container>
    <MainWrapper>
      <Loader />
    </MainWrapper>
  </Container>
);
