import React, { FC, PropsWithChildren } from 'react';
import { styled } from 'styled-components';

const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: repeating-radial-gradient(
    circle at center,
    white 0px,
    white 187px,
    #e8eaef 187px,
    #e8eaef 188px,
    white 188px,
    white 200px,
    #e8eaef 200px,
    #e8eaef 201px,
    white 201px,
    white 232px,
    #e8eaef 232px,
    #e8eaef 233px,
    white 233px,
    white 304px,
    #e8eaef 304px,
    #e8eaef 305px,
    white 305px,
    white 487px,
    #e8eaef 487px,
    #e8eaef 488px,
    white 488px,
    white 685px,
    #e8eaef 685px,
    #e8eaef 686px
  );
`;

export const Container: FC<PropsWithChildren> = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};
