import React, { FC, PropsWithChildren } from 'react';
import { styled } from 'styled-components';

type ButtonProps = {
  onClick: () => void;
};

const StyledButton = styled.a`
  padding: 10px 51px;
  background-color: #d9eb5e;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

export const Button: FC<PropsWithChildren<ButtonProps>> = ({ children, onClick }) => {
  return (
    <StyledButton onClick={onClick} href="https://apps.apple.com/app/id6477531600">
      {children}
    </StyledButton>
  );
};
